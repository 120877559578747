import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./containers/index";
import store from "./config/configureStore";
import { Provider } from "react-redux";
import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";
import { getUserProfile } from "./actions/AuthAction";

registerLocale('ru', ru);

store.dispatch(getUserProfile());

const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./containers/index", () => {
    const NextApp = require("./containers/index").default;
    renderApp(NextApp);
  });
}

serviceWorker.unregister();
