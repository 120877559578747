let apiUrl;
const { protocol, hostname } = window.location;

if (hostname === 'localhost') {
  apiUrl = `${protocol}//${hostname}:4000`;
} else if (hostname === 'admin.youngprofi.com') {
  apiUrl = 'https://api.youngprofi.com';
} else {
  apiUrl = 'https://api.youngprofi.com';
}

const config = {
  urls: {
    api: apiUrl,
    amoFront: 'https://starclass.amocrm.ru',
  },
};

export default config;
