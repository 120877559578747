import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  ANALYTICS_GET_ANALYTIC_PROGRESS,
  ANALYTICS_GET_ANALYTIC_SUCCESS,
  ANALYTICS_GET_ANALYTIC_FAILED,
  ANALYTICS_GET_SEARCH_PROGRESS,
  ANALYTICS_GET_SEARCH_SUCCESS,
  ANALYTICS_GET_SEARCH_FAILED,
  ANALYTICS_GET_BID_INFO_PROGRESS,
  ANALYTICS_GET_BID_INFO_SUCCESS,
  ANALYTICS_GET_BID_INFO_FAILED,
  ANALYTICS_CLEAR_STATE,
} from "../actions/AnalyticsAction";

const initialState = {
  loadActiveStatus: '',
  loadSearchStatus: '',
  loadBidInfoStatus: '',
  active: {},
  search: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_GET_ANALYTIC_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case ANALYTICS_GET_ANALYTIC_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.analytics },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case ANALYTICS_GET_ANALYTIC_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case ANALYTICS_GET_SEARCH_PROGRESS: {
      return update(
        state, {
          search: { $set: {} },
          loadSearchStatus: { $set: PROGRESS },
        },
      );
    }
    case ANALYTICS_GET_SEARCH_SUCCESS: {
      return update(
        state, {
          search: { $set: action.payload },
          loadSearchStatus: { $set: SUCCESS },
        },
      );
    }
    case ANALYTICS_GET_SEARCH_FAILED: {
      return update(
        state, {
          search: { $set: {} },
          loadSearchStatus: { $set: FAILED },
        },
      );
    }
    case ANALYTICS_GET_BID_INFO_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          loadBidInfoStatus: { $set: PROGRESS },
        },
      );
    }
    case ANALYTICS_GET_BID_INFO_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.bid },
          loadBidInfoStatus: { $set: SUCCESS },
        },
      );
    }
    case ANALYTICS_GET_BID_INFO_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          loadBidInfoStatus: { $set: FAILED },
        },
      );
    }
    case ANALYTICS_CLEAR_STATE: {
      return update(
        state,
        {
          loadActiveStatus: { $set: '' },
          loadSearchStatus: { $set: '' },
          loadBidInfoStatus: { $set: '' },
          active: { $set: {} },
          search: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
