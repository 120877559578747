import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  SETTINGS_FORM_SUBMIT_PROGRESS,
  SETTINGS_FORM_SUBMIT_SUCCESS,
  SETTINGS_FORM_SUBMIT_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED,
  SETTINGS_GET_SETTING_PROGRESS,
  SETTINGS_GET_SETTING_SUCCESS,
  SETTINGS_GET_SETTING_FAILED,
  SETTINGS_CLEAR_STATE,
} from '../actions/SettingsAction';

const initialState = {
  loadActiveStatus: '',
  submitStatus: '',
  submitFondyStatus: '',
  submitFondyRecurringStatus: '',
  active: {},
  asyncData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTINGS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: PROGRESS } },
      );
    }
    case SETTINGS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          submitStatus: { $set: SUCCESS },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: FAILED } },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_PROGRESS: {
      return update(
        state, {
          submitFondyStatus: { $set: PROGRESS },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_SUCCESS: {
      return update(
        state, {
          submitFondyStatus: { $set: SUCCESS },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_FAILED: {
      return update(
        state, {
          submitFondyStatus: { $set: FAILED },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS: {
      return update(
        state, {
          submitFondyRecurringStatus: { $set: PROGRESS },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS: {
      return update(
        state, {
          submitFondyRecurringStatus: { $set: SUCCESS },
        },
      );
    }
    case SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED: {
      return update(
        state, {
          submitFondyRecurringStatus: { $set: FAILED },
        },
      );
    }
    case SETTINGS_GET_SETTING_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          asyncData: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case SETTINGS_GET_SETTING_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.settings },
          asyncData: { $set: action.payload.asyncData },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case SETTINGS_GET_SETTING_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          asyncData: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case SETTINGS_CLEAR_STATE: {
      return update(
        state,
        {
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          submitFondyStatus: { $set: '' },
          submitFondyRecurringStatus: { $set: '' },
          active: { $set: {} },
          asyncData: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
