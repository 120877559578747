import {
  passValidation,
  notPassValidation,
  isInvalid,
  FIELD_IS_REQUIRED,
  ENTERED_AN_INVALID_EMAIL,
} from "../../../config/validate";

export default function (form) {
  const result = {};
  const keys = { email: 'email', password: 'password' };
  const { email, password } = form;

  if (!email) {
    result[keys.email] = notPassValidation(FIELD_IS_REQUIRED);
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    result[keys.email] = notPassValidation(ENTERED_AN_INVALID_EMAIL);
  } else {
    result[keys.email] = passValidation();
  }

  if (!password) {
    result[keys.password] = notPassValidation(FIELD_IS_REQUIRED);
  } else {
    result[keys.password] = passValidation();
  }

  return { isInvalid: isInvalid(result), result };
};
