import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";

const KEYS_GET_LIST_PROGRESS = 'KEYS_GET_LIST_PROGRESS';
const KEYS_GET_LIST_SUCCESS = 'KEYS_GET_LIST_SUCCESS';
const KEYS_GET_LIST_FAILED = 'KEYS_GET_LIST_FAILED';
const KEYS_FORM_SUBMIT_PROGRESS = 'KEYS_FORM_SUBMIT_PROGRESS';
const KEYS_FORM_SUBMIT_SUCCESS = 'KEYS_FORM_SUBMIT_SUCCESS';
const KEYS_FORM_SUBMIT_FAILED = 'KEYS_FORM_SUBMIT_FAILED';
const KEYS_GET_KEY_PROGRESS = 'KEYS_GET_KEY_PROGRESS';
const KEYS_GET_KEY_SUCCESS = 'KEYS_GET_KEY_SUCCESS';
const KEYS_GET_KEY_FAILED = 'KEYS_GET_KEY_FAILED';
const KEYS_CLEAR_STATE = 'KEYS_CLEAR_STATE';

const keyGetListProgress = () => ({
  type: KEYS_GET_LIST_PROGRESS,
});

const keyGetListSuccess = payload => ({
  type: KEYS_GET_LIST_SUCCESS,
  payload,
});

const keyGetListFailed = () => ({
  type: KEYS_GET_LIST_FAILED,
});

const keyStartFormSubmit = () => ({
  type: KEYS_FORM_SUBMIT_PROGRESS,
});

const keyFormSubmitSuccess = () => ({
  type: KEYS_FORM_SUBMIT_SUCCESS,
});

const keyFormSubmitFailed = () => ({
  type: KEYS_FORM_SUBMIT_FAILED,
});

const keyGetFormProgress = () => ({
  type: KEYS_GET_KEY_PROGRESS,
});

const keyGetFormSuccess = payload => ({
  type: KEYS_GET_KEY_SUCCESS,
  payload,
});

const keyGetFormFailed = () => ({
  type: KEYS_GET_KEY_FAILED,
});

const keyClearState = () => ({
  type: KEYS_CLEAR_STATE,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    key: doc.key,
    isArchive: doc.isArchive,
    updatedAt: moment(doc.updatedAt).format("LLL"),
  }));

  return { list, pagination };
};

const getKeysList = params => dispatch => {
  const token = getToken();
  dispatch(keyGetListProgress());

  ws.emit('api/app/keys/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(keyGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(keyGetListFailed());
    }
  });
};

const convertSetKeyToDB = (data) => {
  const { key, description } = data;

  return {
    key,
    description,
  };
};

const createKey = (data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetKeyToDB(data);

  dispatch(keyStartFormSubmit());

  ws.emit('api/app/keys/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(keyFormSubmitSuccess());
    } else {
      dispatch(keyFormSubmitFailed());
    }
  });
};

const convertDBKeyToEdit = payload => {
  const { key: keyFromDB } = payload;
  const {
    key: apiKey, description, isArchive, created, modified, createdAt, updatedAt,
  } = keyFromDB;

  const key = {
    key: apiKey,
    description,
  };

  const statistic = {
    created: `${created.name} ${created.surname}`,
    createdAt: moment(createdAt).format("LLL"),
    updated: `${modified.name} ${modified.surname}`,
    updatedAt: moment(updatedAt).format("LLL"),
    isArchive,
  };

  return { key, statistic };
};

const getKey = keyId => dispatch => {
  const token = getToken();
  dispatch(keyGetFormProgress());

  ws.emit('api/app/keys/getById', { token, payload: { keyId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(keyGetFormSuccess(convertDBKeyToEdit(payload)));
    } else {
      dispatch(keyGetFormFailed());
    }
  });
};

const updateKey = (id, data) => dispatch => {
  const token = getToken();
  const convertedData = convertSetKeyToDB(data);

  dispatch(keyStartFormSubmit());

  ws.emit('api/app/keys/updateById', { token, payload: { keyId: id, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(keyFormSubmitSuccess());
    } else {
      dispatch(keyFormSubmitFailed());
    }
  });
};

export {
  KEYS_GET_LIST_PROGRESS,
  KEYS_GET_LIST_SUCCESS,
  KEYS_GET_LIST_FAILED,
  KEYS_FORM_SUBMIT_PROGRESS,
  KEYS_FORM_SUBMIT_SUCCESS,
  KEYS_FORM_SUBMIT_FAILED,
  KEYS_GET_KEY_PROGRESS,
  KEYS_GET_KEY_SUCCESS,
  KEYS_GET_KEY_FAILED,
  KEYS_CLEAR_STATE,
  keyClearState,
  getKeysList,
  createKey,
  getKey,
  updateKey,
};
