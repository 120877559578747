import ws from "../ws";
import getToken from "../getToken";
import moment from "moment";
import "moment/locale/ru";

const FORMS_GET_LIST_PROGRESS = 'FORMS_GET_LIST_PROGRESS';
const FORMS_GET_LIST_SUCCESS = 'FORMS_GET_LIST_SUCCESS';
const FORMS_GET_LIST_FAILED = 'FORMS_GET_LIST_FAILED';
const FORMS_FORM_SUBMIT_PROGRESS = 'FORMS_FORM_SUBMIT_PROGRESS';
const FORMS_FORM_SUBMIT_SUCCESS = 'FORMS_FORM_SUBMIT_SUCCESS';
const FORMS_FORM_SUBMIT_FAILED = 'FORMS_FORM_SUBMIT_FAILED';
const FORMS_GET_FORM_PROGRESS = 'FORMS_GET_FORM_PROGRESS';
const FORMS_GET_FORM_SUCCESS = 'FORMS_GET_FORM_SUCCESS';
const FORMS_GET_FORM_FAILED = 'FORMS_GET_FORM_FAILED';
const FORMS_GET_CODE_PROGRESS = 'FORMS_GET_CODE_PROGRESS';
const FORMS_GET_CODE_SUCCESS = 'FORMS_GET_CODE_SUCCESS';
const FORMS_GET_CODE_FAILED = 'FORMS_GET_CODE_FAILED';
const FORMS_GET_ASYNC_DATA_PROGRESS = 'FORMS_GET_ASYNC_DATA_PROGRESS';
const FORMS_GET_ASYNC_DATA_SUCCESS = 'FORMS_GET_ASYNC_DATA_SUCCESS';
const FORMS_GET_ASYNC_DATA_FAILED = 'FORMS_GET_ASYNC_DATA_FAILED';
const FORMS_DUPLICATE_SUBMIT_PROGRESS = 'FORMS_DUPLICATE_SUBMIT_PROGRESS';
const FORMS_DUPLICATE_SUBMIT_SUCCESS = 'FORMS_DUPLICATE_SUBMIT_SUCCESS';
const FORMS_DUPLICATE_SUBMIT_FAILED = 'FORMS_DUPLICATE_SUBMIT_FAILED';
const FORMS_CLEAR_STATE = 'FORMS_CLEAR_STATE';

const formGetListProgress = () => ({
  type: FORMS_GET_LIST_PROGRESS,
});

const formGetListSuccess = payload => ({
  type: FORMS_GET_LIST_SUCCESS,
  payload,
});

const formGetListFailed = () => ({
  type: FORMS_GET_LIST_FAILED,
});

const formStartFormSubmit = () => ({
  type: FORMS_FORM_SUBMIT_PROGRESS,
});

const formFormSubmitSuccess = () => ({
  type: FORMS_FORM_SUBMIT_SUCCESS,
});

const formFormSubmitFailed = () => ({
  type: FORMS_FORM_SUBMIT_FAILED,
});

const formGetFormProgress = () => ({
  type: FORMS_GET_FORM_PROGRESS,
});

const formGetFormSuccess = payload => ({
  type: FORMS_GET_FORM_SUCCESS,
  payload,
});

const formGetFormFailed = () => ({
  type: FORMS_GET_FORM_FAILED,
});

const formGetCodeProgress = () => ({
  type: FORMS_GET_CODE_PROGRESS,
});

const formGetCodeSuccess = payload => ({
  type: FORMS_GET_CODE_SUCCESS,
  payload,
});

const formGetCodeFailed = () => ({
  type: FORMS_GET_CODE_FAILED,
});

const formGetAsyncDataProgress = () => ({
  type: FORMS_GET_ASYNC_DATA_PROGRESS,
});

const formGetAsyncDataSuccess = payload => ({
  type: FORMS_GET_ASYNC_DATA_SUCCESS,
  payload,
});

const formGetAsyncDataFailed = () => ({
  type: FORMS_GET_ASYNC_DATA_FAILED,
});

const formClearState = () => ({
  type: FORMS_CLEAR_STATE,
});

const formStartDuplicateSubmit = () => ({
  type: FORMS_DUPLICATE_SUBMIT_PROGRESS,
});

const formDuplicateSubmitSuccess = () => ({
  type: FORMS_DUPLICATE_SUBMIT_SUCCESS,
});

const formDuplicateSubmitFailed = () => ({
  type: FORMS_DUPLICATE_SUBMIT_FAILED,
});

const convertListToTable = payload => {
  const { data } = payload;
  const {
    docs, hasNextPage, hasPrevPage, limit, nextPage, page, pagingCounter, prevPage, totalDocs, totalPages,
  } = data;

  const pagination = {
    docsNumber: docs.length,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    page,
    pagingCounter,
    prevPage,
    totalDocs,
    totalPages,
  };

  let number = page * limit - limit;

  const list = docs.map(doc => ({
    number: ++number,
    id: doc.id,
    name: doc.name,
    lead: doc.lead.name,
    needPaid: doc.lead.needPaid,
    isArchive: doc.isArchive,
    updatedAt: moment(doc.updatedAt).format("LLL"),
  }));

  return { list, pagination };
};

const getFormsList = params => dispatch => {
  const token = getToken();
  dispatch(formGetListProgress());

  ws.emit('api/app/forms/list', { token, payload: params }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(formGetListSuccess(convertListToTable(payload)));
    } else {
      dispatch(formGetListFailed());
    }
  });
};

const convertSetFormToDB = (data, fields) => {
  const {
    name, productName, lead, price, needPaid, free, freeStatus, paid: paidSelect, paidStatus, payment, amount, currency,
    isRecurrentPayment, redirect, cancel,
  } = data;

  let paid;

  if (needPaid) {
    paid = {
      pipeline: paidSelect.value,
      statusId: paidStatus.value,
      payment: payment.value,
      amount: amount,
      currency: currency.value,
      isRecurrentPayment: isRecurrentPayment,
    };
  } else {
    paid = {
      pipeline: null,
      statusId: null,
      payment: null,
      amount: null,
      currency: null,
      isRecurrentPayment: false,
    };
  }

  const fieldKeys = Object.keys(fields);

  return {
    name,
    productName,
    lead: {
      name: lead,
      price: price.length ? price : null,
      needPaid,
      free: {
        pipeline: free.value,
        statusId: freeStatus.value,
      },
      paid,
    },
    customFields: fieldKeys.reduce((result, key) => {
      if (fields[key].length) {
        result.push({ field: key, values: fields[key] });
      }

      return result;
    }, []),
    code: {
      redirect,
      cancel,
    },
  };
};

const createForm = (data, fields) => dispatch => {
  const token = getToken();
  const convertedData = convertSetFormToDB(data, fields);

  dispatch(formStartFormSubmit());

  ws.emit('api/app/forms/create', { token, payload: convertedData }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(formFormSubmitSuccess());
    } else {
      dispatch(formFormSubmitFailed());
    }
  });
};

const convertDBFormToEdit = payload => {
  const { form: formFromDB } = payload;
  const {
    name: formName, productName, lead, customFields, created, modified, statistics, createdAt, updatedAt,
    isArchive, code,
  } = formFromDB;
  const { name: leadName, price, needPaid, free, paid } = lead;
  const { pipeline: freePipeline, statusId: freeStatusId } = free;
  const { pipeline: paidPipeline, statusId: paidStatusId, payment, amount, currency, isRecurrentPayment } = paid;
  const { redirect, cancel } = code;

  const getValueForSelect = data => ({ value: data._id, label: data.name });

  const getStatusValue = (pipeline, statusId) => {
    const { statuses } = pipeline;
    const { name: statusName } = statuses.find(status => status.amoId === statusId);

    return { value: statusId, label: statusName };
  };

  const form = {
    name: formName,
    productName,
    lead: leadName,
    needPaid,
    free: getValueForSelect(freePipeline),
    freeStatus: getStatusValue(freePipeline, freeStatusId),
    redirect,
    cancel,
  };

  if (price) {
    form.price = price.toString();
  }

  if (paidPipeline) {
    form.paid = getValueForSelect(paidPipeline);
    form.paidStatus = getStatusValue(paidPipeline, paidStatusId);
    form.payment = { value: payment._id, label: payment.name, type: payment.type };
    form.amount = amount.toString();
    form.currency = getValueForSelect(currency);
    form.isRecurrentPayment = typeof isRecurrentPayment === 'boolean' ? isRecurrentPayment : false;
  }

  const statistic = {
    created: `${created.name} ${created.surname}`,
    createdAt: moment(createdAt).format("LLL"),
    updated: `${modified.name} ${modified.surname}`,
    updatedAt: moment(updatedAt).format("LLL"),
    isArchive,
    leads: statistics.leads,
  };

  return { form, fields: customFields, statistic };
};

const getForm = formId => dispatch => {
  const token = getToken();
  dispatch(formGetFormProgress());

  ws.emit('api/app/forms/getById', { token, payload: { formId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(formGetFormSuccess(convertDBFormToEdit(payload)));
    } else {
      dispatch(formGetFormFailed());
    }
  });
};

const updateForm = (id, data, fields) => dispatch => {
  const token = getToken();
  const convertedData = convertSetFormToDB(data, fields);

  dispatch(formStartFormSubmit());

  ws.emit('api/app/forms/updateById', { token, payload: { formId: id, data: convertedData} }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(formFormSubmitSuccess());
    } else {
      dispatch(formFormSubmitFailed());
    }
  });
};

const transformAsyncData = data => ({
  pipelines: data.pipelines.map(pipeline => {
    const { id, name, statuses: statusesData, isArchive } = pipeline;
    const statuses = statusesData.sort((status1, status2) => status1.sort - status2.sort).map(status => ({
      value: status.amoId,
      label: status.name,
      color: status.color,
      disabled: status.isArchive,
    }));

    return { value: id, label: name, statuses, disabled: isArchive };
  }),
  payments: data.payments.map(payment => ({ value: payment.id, label: payment.name, type: payment.type })),
  currencies: data.currencies.map(currency => ({ value: currency.id, label: currency.name })),
  fields: data.fields.map(field => {
    const { enums } = field;
    return { ...field, enums: enums.map(item => ({ value: item.amoId, label: item.value })) };
  }),
  uah: data.currencies.filter(currency => currency.currency === 'uah')
    .map(currency => ({ value: currency.id, label: currency.name }))[0],
});

const getAsyncData = () => dispatch => {
  const token = getToken();
  dispatch(formGetAsyncDataProgress());

  ws.emit('api/app/forms/getAsyncData', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(formGetAsyncDataSuccess(transformAsyncData(payload)));
    } else {
      dispatch(formGetAsyncDataFailed());
    }
  });
};

const getFormSettings = formId => dispatch => {
  const token = getToken();
  dispatch(formGetCodeProgress());

  ws.emit('api/app/forms/getFormCodeSetting', { token, payload: { formId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(formGetCodeSuccess(payload));
    } else {
      dispatch(formGetCodeFailed());
    }
  });
};

const setFormSettings = (formId, data) => dispatch => {
  const token = getToken();
  dispatch(formStartFormSubmit());

  ws.emit('api/app/forms/setFormCodeSetting', { token, payload: { formId, data } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(formFormSubmitSuccess());
    } else {
      dispatch(formFormSubmitFailed());
    }
  });
};

const createDuplicate = formId => dispatch => {
  const token = getToken();
  dispatch(formStartDuplicateSubmit());

  ws.emit('api/app/forms/createDuplicate', { token, payload: { formId } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(formDuplicateSubmitSuccess());
    } else {
      dispatch(formDuplicateSubmitFailed());
    }
  });
};

export {
  FORMS_GET_LIST_PROGRESS,
  FORMS_GET_LIST_SUCCESS,
  FORMS_GET_LIST_FAILED,
  FORMS_FORM_SUBMIT_PROGRESS,
  FORMS_FORM_SUBMIT_SUCCESS,
  FORMS_FORM_SUBMIT_FAILED,
  FORMS_GET_FORM_PROGRESS,
  FORMS_GET_FORM_SUCCESS,
  FORMS_GET_FORM_FAILED,
  FORMS_GET_CODE_PROGRESS,
  FORMS_GET_CODE_SUCCESS,
  FORMS_GET_CODE_FAILED,
  FORMS_GET_ASYNC_DATA_PROGRESS,
  FORMS_GET_ASYNC_DATA_SUCCESS,
  FORMS_GET_ASYNC_DATA_FAILED,
  FORMS_CLEAR_STATE,
  FORMS_DUPLICATE_SUBMIT_PROGRESS,
  FORMS_DUPLICATE_SUBMIT_SUCCESS,
  FORMS_DUPLICATE_SUBMIT_FAILED,
  formClearState,
  getFormsList,
  createForm,
  getForm,
  updateForm,
  getAsyncData,
  getFormSettings,
  setFormSettings,
  createDuplicate,
};
