import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  BIDS_GET_FAILED_LIST_PROGRESS,
  BIDS_GET_FAILED_LIST_SUCCESS,
  BIDS_GET_FAILED_LIST_FAILED,
  BIDS_GET_FAILED_ALL_BIDS_PROGRESS,
  BIDS_GET_FAILED_ALL_BIDS_SUCCESS,
  BIDS_GET_FAILED_ALL_BIDS_FAILED,
  BIDS_GET_FAILED_ALL_BIDS_CLEAR,
  BIDS_CLEAR_STATE,
} from '../actions/BidsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  list: [],
  failedBids: [], // Это свойство не очищается при вызове BIDS_CLEAR_STATE, так как оно глобально
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BIDS_GET_FAILED_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          loadListStatus: { $set: PROGRESS },
        },
      );
    }
    case BIDS_GET_FAILED_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          loadListStatus: { $set: SUCCESS },
        },
      );
    }
    case BIDS_GET_FAILED_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          loadListStatus: { $set: FAILED },
        },
      );
    }
    case BIDS_GET_FAILED_ALL_BIDS_PROGRESS: {
      return update(
        state, {
          failedBids: { $set: [] },
        },
      );
    }
    case BIDS_GET_FAILED_ALL_BIDS_SUCCESS: {
      return update(
        state, {
          failedBids: { $set: action.payload.list },
        },
      );
    }
    case BIDS_GET_FAILED_ALL_BIDS_FAILED: {
      return update(
        state, {
          failedBids: { $set: [] },
        },
      );
    }
    case BIDS_GET_FAILED_ALL_BIDS_CLEAR: {
      return update(
        state,
        {
          failedBids: { $set: [] },
        },
      );
    }
    case BIDS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          list: { $set: [] },
        },
      );
    }
    default:
      return state;
  }
}
