import store from "./config/configureStore";

const getToken = () => {
  let token = null;
  const { tokens } = store.getState().auth;

  if (tokens) {
    token = tokens.accessToken;
  }

  return token;
};

export default getToken;
