import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from "../config/types";
import {
  MERCHANTS_GET_LIST_PROGRESS,
  MERCHANTS_GET_LIST_SUCCESS,
  MERCHANTS_GET_LIST_FAILED,
  MERCHANTS_FORM_SUBMIT_PROGRESS,
  MERCHANTS_FORM_SUBMIT_SUCCESS,
  MERCHANTS_FORM_SUBMIT_FAILED,
  MERCHANTS_GET_MERCHANT_PROGRESS,
  MERCHANTS_GET_MERCHANT_SUCCESS,
  MERCHANTS_GET_MERCHANT_FAILED,
  MERCHANTS_CLEAR_STATE,
} from '../actions/MerchantsAction';

const initialState = {
  loadListStatus: '', // progress/success/failed
  loadActiveStatus: '', // progress/success/failed
  submitStatus: '', // progress/success/failed
  list: [],
  pagination: {},
  active: {},
  statistic: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MERCHANTS_GET_LIST_PROGRESS: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: PROGRESS },
        },
      );
    }
    case MERCHANTS_GET_LIST_SUCCESS: {
      return update(
        state, {
          list: { $set: action.payload.list },
          pagination: { $set: action.payload.pagination },
          loadListStatus: { $set: SUCCESS },
        },
      );
    }
    case MERCHANTS_GET_LIST_FAILED: {
      return update(
        state, {
          list: { $set: [] },
          pagination: { $set: {} },
          loadListStatus: { $set: FAILED },
        },
      );
    }
    case MERCHANTS_FORM_SUBMIT_PROGRESS: {
      return update(
        state,
        { submitStatus: { $set: PROGRESS } },
      );
    }
    case MERCHANTS_FORM_SUBMIT_SUCCESS: {
      return update(
        state,
        {
          submitStatus: { $set: SUCCESS },
        },
      );
    }
    case MERCHANTS_FORM_SUBMIT_FAILED: {
      return update(
        state,
        { submitStatus: { $set: FAILED } },
      );
    }
    case MERCHANTS_GET_MERCHANT_PROGRESS: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadActiveStatus: { $set: PROGRESS },
        },
      );
    }
    case MERCHANTS_GET_MERCHANT_SUCCESS: {
      return update(
        state, {
          active: { $set: action.payload.merchant },
          statistic: { $set: action.payload.statistic || {} },
          loadActiveStatus: { $set: SUCCESS },
        },
      );
    }
    case MERCHANTS_GET_MERCHANT_FAILED: {
      return update(
        state, {
          active: { $set: {} },
          statistic: { $set: {} },
          loadActiveStatus: { $set: FAILED },
        },
      );
    }
    case MERCHANTS_CLEAR_STATE: {
      return update(
        state,
        {
          loadListStatus: { $set: '' },
          loadActiveStatus: { $set: '' },
          submitStatus: { $set: '' },
          list: { $set: [] },
          pagination: { $set: {} },
          active: { $set: {} },
          statistic: { $set: {} },
        },
      );
    }
    default:
      return state;
  }
}
