import ThemeOptions from "./ThemeOptions";
import auth from "./AuthReducer";
import users from "./UsersReducer";
import forms from "./FormsReducer";
import settings from "./SettingsReducer";
import analytics from "./AnalyticsReduces";
import merchants from "./MerchantsReducer";
import keys from "./KeysReducer";
import bids from "./BidsReducer";

export default {
  ThemeOptions,
  auth,
  users,
  forms,
  settings,
  analytics,
  merchants,
  keys,
  bids,
};
