import ws from '../ws';
import getToken from '../getToken';

const SETTINGS_FORM_SUBMIT_PROGRESS = 'SETTINGS_FORM_SUBMIT_PROGRESS';
const SETTINGS_FORM_SUBMIT_SUCCESS = 'SETTINGS_FORM_SUBMIT_SUCCESS';
const SETTINGS_FORM_SUBMIT_FAILED = 'SETTINGS_FORM_SUBMIT_FAILED';
const SETTINGS_FORM_SUBMIT_FONDY_PROGRESS = 'SETTINGS_FORM_SUBMIT_FONDY_PROGRESS';
const SETTINGS_FORM_SUBMIT_FONDY_SUCCESS = 'SETTINGS_FORM_SUBMIT_FONDY_SUCCESS';
const SETTINGS_FORM_SUBMIT_FONDY_FAILED = 'SETTINGS_FORM_SUBMIT_FONDY_FAILED';
const SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS = 'SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS';
const SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS = 'SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS';
const SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED = 'SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED';
const SETTINGS_GET_SETTING_PROGRESS = 'SETTINGS_GET_SETTING_PROGRESS';
const SETTINGS_GET_SETTING_SUCCESS = 'SETTINGS_GET_SETTING_SUCCESS';
const SETTINGS_GET_SETTING_FAILED = 'SETTINGS_GET_SETTING_FAILED';
const SETTINGS_CLEAR_STATE = 'SETTINGS_CLEAR_STATE';

const settingStartFormSubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_PROGRESS,
});

const settingFormSubmitSuccess = () => ({
  type: SETTINGS_FORM_SUBMIT_SUCCESS,
});

const settingFormSubmitFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_FAILED,
});

const settingGetSettingProgress = () => ({
  type: SETTINGS_GET_SETTING_PROGRESS,
});

const settingGetSettingSuccess = payload => ({
  type: SETTINGS_GET_SETTING_SUCCESS,
  payload,
});

const settingStartFormFondySubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_PROGRESS,
});

const settingFormSubmitFondySuccess = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_SUCCESS,
});

const settingFormSubmitFondyFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_FAILED,
});

const settingStartFormFondyRecurringSubmit = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS,
});

const settingFormSubmitFondyRecurringSuccess = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS,
});

const settingFormSubmitFondyRecurringFailed = () => ({
  type: SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED,
});

const settingGetSettingFailed = () => ({
  type: SETTINGS_GET_SETTING_FAILED,
});

const settingClearState = () => ({
  type: SETTINGS_CLEAR_STATE,
});

const convertSetSettingToDB = data => {
  const { period, field } = data;

  return {
    period,
    field,
  };
};

const convertDBSettingToEdit = payload => {
  const { settings: settingsFromDB, asyncData } = payload;
  const { merchants, keys } = asyncData;
  const { period, field, merchant, recurringMerchant } = settingsFromDB;
  const getMerchantLabel = (merchant) => `${merchant.name} (ID: ${merchant.merchantId})`;

  const settings = {
    period,
    field,
    fondy: {
      merchant: merchant ? ({ value: merchant.id, label: getMerchantLabel(merchant) }) : null,
    },
    fondyRecurring: {
      merchant: recurringMerchant ? ({ value: recurringMerchant.id, label: getMerchantLabel(recurringMerchant) }) : null,
    }
  };

  return {
    settings,
    asyncData: {
      merchants: merchants.map(merchant => ({ value: merchant.id, label: getMerchantLabel(merchant) })),
      keys,
    },
  };
};

const getSettings = () => dispatch => {
  const token = getToken();
  dispatch(settingGetSettingProgress());

  ws.emit('api/app/settings/get', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(settingGetSettingSuccess(convertDBSettingToEdit(payload)));
    } else {
      dispatch(settingGetSettingFailed());
    }
  });
};

const updateSettings = data => dispatch => {
  const token = getToken();
  const convertedData = convertSetSettingToDB(data);

  dispatch(settingStartFormSubmit());

  ws.emit('api/app/settings/update', { token, payload: { data: convertedData } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitSuccess());
    } else {
      dispatch(settingFormSubmitFailed());
    }
  });
};

const updateFondySettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormFondySubmit());

  ws.emit('api/app/settings/updateFondy', { token, payload: { data } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitFondySuccess());
    } else {
      dispatch(settingFormSubmitFondyFailed());
    }
  });
};

const updateFondyRecurringSettings = data => dispatch => {
  const token = getToken();
  dispatch(settingStartFormFondyRecurringSubmit());

  ws.emit('api/app/settings/updateFondyRecurring', { token, payload: { data } }, (result) => {
    const { status } = result;

    if (status === 'ok') {
      dispatch(settingFormSubmitFondyRecurringSuccess());
    } else {
      dispatch(settingFormSubmitFondyRecurringFailed());
    }
  });
};

export {
  SETTINGS_FORM_SUBMIT_PROGRESS,
  SETTINGS_FORM_SUBMIT_SUCCESS,
  SETTINGS_FORM_SUBMIT_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_FAILED,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_PROGRESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_SUCCESS,
  SETTINGS_FORM_SUBMIT_FONDY_RECURRING_FAILED,
  SETTINGS_GET_SETTING_PROGRESS,
  SETTINGS_GET_SETTING_SUCCESS,
  SETTINGS_GET_SETTING_FAILED,
  SETTINGS_CLEAR_STATE,
  settingClearState,
  getSettings,
  updateSettings,
  updateFondySettings,
  updateFondyRecurringSettings,
};
