import ws from '../ws';
import getToken from '../getToken';
import moment from 'moment';

const ANALYTICS_GET_ANALYTIC_PROGRESS = 'ANALYTICS_GET_ANALYTIC_PROGRESS';
const ANALYTICS_GET_ANALYTIC_SUCCESS = 'ANALYTICS_GET_ANALYTIC_SUCCESS';
const ANALYTICS_GET_ANALYTIC_FAILED = 'ANALYTICS_GET_ANALYTIC_FAILED';
const ANALYTICS_GET_SEARCH_PROGRESS = 'ANALYTICS_GET_SEARCH_PROGRESS';
const ANALYTICS_GET_SEARCH_SUCCESS = 'ANALYTICS_GET_SEARCH_SUCCESS';
const ANALYTICS_GET_SEARCH_FAILED = 'ANALYTICS_GET_SEARCH_FAILED';
const ANALYTICS_GET_BID_INFO_PROGRESS = 'ANALYTICS_GET_BID_INFO_PROGRESS';
const ANALYTICS_GET_BID_INFO_SUCCESS = 'ANALYTICS_GET_BID_INFO_SUCCESS';
const ANALYTICS_GET_BID_INFO_FAILED = 'ANALYTICS_GET_BID_INFO_FAILED';
const ANALYTICS_CLEAR_STATE = 'ANALYTICS_CLEAR_STATE';

const analyticGetAnalyticProgress = () => ({
  type: ANALYTICS_GET_ANALYTIC_PROGRESS,
});

const analyticGetAnalyticSuccess = payload => ({
  type: ANALYTICS_GET_ANALYTIC_SUCCESS,
  payload,
});

const analyticGetAnalyticFailed = () => ({
  type: ANALYTICS_GET_ANALYTIC_FAILED,
});

const analyticGetSearchProgress = () => ({
  type: ANALYTICS_GET_SEARCH_PROGRESS,
});

const analyticGetSearchSuccess = payload => ({
  type: ANALYTICS_GET_SEARCH_SUCCESS,
  payload,
});

const analyticGetSearchFailed = () => ({
  type: ANALYTICS_GET_SEARCH_FAILED,
});

const analyticGetBidInfoProgress = () => ({
  type: ANALYTICS_GET_BID_INFO_PROGRESS,
});

const analyticGetBidInfoSuccess = payload => ({
  type: ANALYTICS_GET_BID_INFO_SUCCESS,
  payload,
});

const analyticGetBidInfoFailed = () => ({
  type: ANALYTICS_GET_BID_INFO_FAILED,
});

const analyticClearState = () => ({
  type: ANALYTICS_CLEAR_STATE,
});

const convertDBBidInfoToEdit = payload => {
  const { bid: bidFromDB } = payload;
  const {
    person, form, leadId, contactId, utm, invoice: rawInvoice, isPaid, info,
  } = bidFromDB;
  const {
    isForm, isFormError, history, referrer, location, browser, cookies, screen, time, geo,
  } = info;

  const invoice = !isPaid ? {} : {
    ...rawInvoice,
    payment: rawInvoice.payment.name,
  };

  const bid = {
    person,
    form: {
      id: form._id,
      name: form.lead.name,
    },
    leadId,
    contactId,
    utm,
    invoice,
    isPaid,
    info: {
      isForm,
      isFormError,
      history,
      referrer,
      location,
    },
    browser,
    cookies,
    screen,
    time,
    geo,
  };

  return { bid };
};

const convertBids = bids => bids.map(bid => ({
  id: bid._id,
  date: moment(bid.updatedAt).format('LLL'),
  lead: bid.form.lead.name,
  name: bid.person.name,
  phone: bid.person.phone,
  email: bid.person.email,
  isPaid: bid.isPaid,
  leadId: bid.leadId,
}));

const convertDBSearchBidToEdit = payload => ({
  search: convertBids(payload.bids),
});

const convertDBAnalyticsToEdit = payload => {
  const { analytics: analyticsFromDB } = payload;
  const { today, yesterday, bids } = analyticsFromDB;

  const analytics = {
    today,
    yesterday,
    bids: convertBids(bids),
  };

  return { analytics };
};

const getAnalytics = () => dispatch => {
  const token = getToken();
  dispatch(analyticGetAnalyticProgress());

  ws.emit('api/app/analytics/get', { token }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(analyticGetAnalyticSuccess(convertDBAnalyticsToEdit(payload)));
    } else {
      dispatch(analyticGetAnalyticFailed());
    }
  });
};

const searchBid = leadId => dispatch => {
  const token = getToken();
  dispatch(analyticGetSearchProgress());

  ws.emit('api/app/analytics/searchBid', { token, payload: { leadId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(analyticGetSearchSuccess(convertDBSearchBidToEdit(payload)));
    } else {
      dispatch(analyticGetSearchFailed());
    }
  });
};

const getBidInfo = bidId => dispatch => {
  const token = getToken();
  dispatch(analyticGetBidInfoProgress());

  ws.emit('api/app/analytics/bidInfo', { token, payload: { bidId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      dispatch(analyticGetBidInfoSuccess(convertDBBidInfoToEdit(payload)));
    } else {
      dispatch(analyticGetBidInfoFailed());
    }
  });
};

export {
  ANALYTICS_GET_ANALYTIC_PROGRESS,
  ANALYTICS_GET_ANALYTIC_SUCCESS,
  ANALYTICS_GET_ANALYTIC_FAILED,
  ANALYTICS_GET_SEARCH_PROGRESS,
  ANALYTICS_GET_SEARCH_SUCCESS,
  ANALYTICS_GET_SEARCH_FAILED,
  ANALYTICS_GET_BID_INFO_PROGRESS,
  ANALYTICS_GET_BID_INFO_SUCCESS,
  ANALYTICS_GET_BID_INFO_FAILED,
  ANALYTICS_CLEAR_STATE,
  analyticClearState,
  getAnalytics,
  searchBid,
  getBidInfo,
};
