import ws from "../ws";
import getToken from "../getToken";

const BIDS_GET_FAILED_LIST_PROGRESS = 'BIDS_GET_FAILED_LIST_PROGRESS';
const BIDS_GET_FAILED_LIST_SUCCESS = 'BIDS_GET_FAILED_LIST_SUCCESS';
const BIDS_GET_FAILED_LIST_FAILED = 'BIDS_GET_FAILED_LIST_FAILED';
const BIDS_GET_FAILED_ALL_BIDS_PROGRESS = 'BIDS_GET_FAILED_ALL_BIDS_PROGRESS';
const BIDS_GET_FAILED_ALL_BIDS_SUCCESS = 'BIDS_GET_FAILED_ALL_BIDS_SUCCESS';
const BIDS_GET_FAILED_ALL_BIDS_FAILED = 'BIDS_GET_FAILED_ALL_BIDS_FAILED';
const BIDS_GET_FAILED_ALL_BIDS_CLEAR = 'BIDS_GET_FAILED_ALL_BIDS_CLEAR';
const BIDS_CLEAR_STATE = 'BIDS_CLEAR_STATE';

const failedBidGetListProgress = () => ({
  type: BIDS_GET_FAILED_LIST_PROGRESS,
});

const failedBidGetListSuccess = payload => ({
  type: BIDS_GET_FAILED_LIST_SUCCESS,
  payload,
});

const failedBidGetListFailed = () => ({
  type: BIDS_GET_FAILED_LIST_FAILED,
});

const failedBidGetAllBidsProgress = () => ({
  type: BIDS_GET_FAILED_ALL_BIDS_PROGRESS,
});

const failedBidGetAllBidsSuccess = payload => ({
  type: BIDS_GET_FAILED_ALL_BIDS_SUCCESS,
  payload,
});

const failedBidGetAllBidsFailed = () => ({
  type: BIDS_GET_FAILED_ALL_BIDS_FAILED,
});

const failedBidClear = () => ({
  type: BIDS_GET_FAILED_ALL_BIDS_CLEAR,
});

const bidClearState = () => ({
  type: BIDS_CLEAR_STATE,
});

const getFailedBidsList = () => dispatch => {
  const token = getToken();
  dispatch(failedBidGetListProgress());

  ws.emit('api/app/bids/failedList', { token }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      dispatch(failedBidGetListSuccess(payload));
    } else {
      dispatch(failedBidGetListFailed());
    }
  });
};

const removeFailedBid = failedBidId => new Promise((resolve, reject) => {
  const token = getToken();

  ws.emit('api/app/bids/removeFailedBid', { token, payload: { failedBidId } }, (result) => {
    const { status, payload } = result;

    if (status === 'ok') {
      resolve(payload.failedBidId);
    } else {
      reject();
    }
  });
});

const initFailedBidsListener = () => dispatch => {
  const token = getToken();
  dispatch(failedBidGetAllBidsProgress());

  ws.emit('api/app/bids/failedList', { token }, (data) => {
    const { status, payload } = data;

    if (status === 'ok') {
      ws.on('bids/getFailedBid', (payload) => {
        dispatch(failedBidGetAllBidsSuccess(payload));
      });

      dispatch(failedBidGetAllBidsSuccess(payload));
    } else {
      dispatch(failedBidGetAllBidsFailed());
    }
  });
};

export {
  BIDS_GET_FAILED_LIST_PROGRESS,
  BIDS_GET_FAILED_LIST_SUCCESS,
  BIDS_GET_FAILED_LIST_FAILED,
  BIDS_GET_FAILED_ALL_BIDS_PROGRESS,
  BIDS_GET_FAILED_ALL_BIDS_SUCCESS,
  BIDS_GET_FAILED_ALL_BIDS_FAILED,
  BIDS_GET_FAILED_ALL_BIDS_CLEAR,
  BIDS_CLEAR_STATE,
  bidClearState,
  getFailedBidsList,
  removeFailedBid,
  initFailedBidsListener,
  failedBidClear,
};
