import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

import LoginPage from "../../containers/Account/Login";

const Analytics = lazy(() => import("../../containers/Analytics"));
const Users = lazy(() => import("../../containers/Users"));
const Forms = lazy(() => import("../../containers/Forms"));
const Settings = lazy(() => import("../../containers/Settings"));
const Merchants = lazy(() => import("../../containers/Merchants"));
const Keys = lazy(() => import("../../containers/Keys"));
const Bids = lazy(() => import("../../containers/Bids"));

const AppMain = () => {
    return (
      <Fragment>

        {/* Analytics */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/analytics" component={Analytics}/>
        </Suspense>

        {/* Forms */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/forms" component={Forms}/>
        </Suspense>

        {/* Users */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/users" component={Users}/>
        </Suspense>

        {/* Settings */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/settings" component={Settings}/>
        </Suspense>

        {/* Merchants */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/merchants" component={Merchants}/>
        </Suspense>

        {/* Keys */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise"/>
              </div>
              <h6 className="mt-5">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/keys" component={Keys}/>
        </Suspense>

        {/* Account */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party"/>
              </div>
              <h6 className="mt-3">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/account" component={LoginPage}/>
        </Suspense>

        {/* Bids */}

        <Suspense fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party"/>
              </div>
              <h6 className="mt-3">
                Подождите
              </h6>
            </div>
          </div>
        }>
          <Route path="/bids" component={Bids}/>
        </Suspense>

        <Route exact path="/" render={() => (
            <Redirect to="/analytics"/>
        )}/>

        <ToastContainer/>
      </Fragment>
    )
};

export default AppMain;
